import React from 'react';

export default class StatsPage extends React.Component {
	render () {
		return (
			<div id="StatsPage" className="statPage">
				<h1>*Statistics Page WIP*</h1>
			</div>
		);
	}
}
